import {useCallback, useEffect} from 'react';
import {
  useTableFeatures,
  TableColumnDefinition,
  useTableSelection,
  createTableColumn,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableSelectionCell,
  TableCellLayout,
  TableColumnId,
  useTableSort,
  Link
} from '@fluentui/react-components';

import {AxisDevice} from '../../../api/__generated__';
import {ACAPS} from '../../../types';

interface Props {
  readonly devices: Partial<AxisDevice>[];
  readonly selectedDevices: Partial<AxisDevice>[];
  readonly devicesSelectHandler: (devicesList: Partial<AxisDevice>[]) => void;
  readonly setOpenSidebar: (serial: string) => void;
}

type DevicesTableItem = {
  model: string;
  serial: string;
  aoaState: string;
  analyticsVersion: string;
};

const columns: TableColumnDefinition<DevicesTableItem>[] = [
  createTableColumn<DevicesTableItem>({
    columnId: 'model',
    compare: (a, b) => a.model.localeCompare(b.model)
  }),
  createTableColumn<DevicesTableItem>({
    columnId: 'serial',
    compare: (a, b) => a.serial.localeCompare(b.serial)
  }),
  createTableColumn<DevicesTableItem>({
    columnId: 'aoa'
  })
];

const DevicesListView = ({
  devices,
  selectedDevices,
  devicesSelectHandler,
  setOpenSidebar
}: Props) => {
  // Table row functionalities
  const {
    getRows,
    selection: {selectedRows, toggleAllRows, toggleRow},
    sort: {getSortDirection, toggleColumnSort, sort}
  } = useTableFeatures(
    {
      columns,
      items: devices.map(device => ({
        model: device.model?.name || '',
        serial: device.serial || '',
        aoaState:
          device.acaps?.find(acap => acap.name === ACAPS.AOA || acap.name === ACAPS.AOA_BETA)
            ?.status || 'NOT INSTALLED',
        analyticsVersion:
          device.acaps?.find(acap => acap.name === ACAPS.AOA || acap.name === ACAPS.AOA_BETA)
            ?.version || ''
      }))
    },
    [
      useTableSelection({
        selectionMode: 'multiselect'
      }),
      useTableSort({defaultSortState: {sortColumn: 'model', sortDirection: 'ascending'}})
    ]
  );

  const rows = sort(
    getRows(row => {
      const isSelected = Boolean(selectedDevices.find(dev => dev.serial === row.item.serial));

      return {
        ...row,
        onClick: (e: React.MouseEvent) => {
          toggleRow(e, row.rowId);
        },
        onKeyDown: (e: React.KeyboardEvent) => {
          if (e.key === ' ') {
            e.preventDefault();
            toggleRow(e, row.rowId);
          }
        },
        isSelected,
        appearance: isSelected ? ('brand' as const) : ('none' as const)
      };
    })
  );

  useEffect(() => {
    const selectedDev = devices.filter((_, index) => selectedRows.has(index));
    devicesSelectHandler(selectedDev);
  }, [selectedRows, devices, devicesSelectHandler]);

  const toggleAllKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === ' ') {
        toggleAllRows(e);
        e.preventDefault();
      }
    },
    [toggleAllRows]
  );

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => toggleColumnSort(e, columnId),
    sortDirection: getSortDirection(columnId)
  });

  const hasAllDevicesSelected = selectedDevices.length === devices.length && devices.length > 0;
  const hasNoDevicesSelected = selectedDevices.length === 0;

  return (
    <Table aria-label="Registered devices">
      <TableHeader>
        <TableRow>
          <TableSelectionCell
            checked={hasAllDevicesSelected || (hasNoDevicesSelected ? false : 'mixed')}
            onClick={toggleAllRows}
            onKeyDown={toggleAllKeydown}
            checkboxIndicator={{'aria-label': 'Select all devices'}}
          />

          <TableHeaderCell {...headerSortProps('model')}>Model</TableHeaderCell>
          <TableHeaderCell {...headerSortProps('serial')}>Mac/Sn</TableHeaderCell>
          <TableHeaderCell>{ACAPS.AOA}</TableHeaderCell>
          <TableHeaderCell>Analytics version</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {rows.map(({item, isSelected, onClick, onKeyDown, appearance}) => (
          <TableRow
            key={item.serial}
            onClick={onClick}
            onKeyDown={onKeyDown}
            aria-selected={isSelected}
            appearance={appearance}
          >
            <TableSelectionCell
              checked={isSelected}
              checkboxIndicator={{'aria-label': 'Select device'}}
            />
            <TableCell>
              <TableCellLayout>{item.model}</TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout onClick={e => e.stopPropagation()}>
                <Link onClick={() => setOpenSidebar(item.serial)}>{item.serial} </Link>
              </TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>{item.aoaState}</TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>{item.analyticsVersion}</TableCellLayout>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DevicesListView;
