import {useCallback, useEffect} from 'react';

import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';

import {
  ResourceGroupTreeView,
  useResourceGroupDataTree,
  useResourceGroupTreeControl
} from '@axteams-one/resource-group-tree';
import {AllResourceGroupsDocument} from '../api/__generated__';
import {setSelectedResourceGroup} from '../reducers/resourceGroupsSlice';
import {useAppDispatch} from '../store/hooks';
import {createGraphQLClient} from '../api/provider';
import {Sidebar} from '@axteams-one/sidebar';

const ResourceGroupTree = () => {
  const {organization, organizations, language} = usePopulatedTopbarValues();

  const dispatch = useAppDispatch();

  const client = createGraphQLClient();

  const dataSource = useCallback(
    async (skip: number, take: number) => {
      const query = await client
        .query(AllResourceGroupsDocument, {
          organizationArn: organization?.arn ?? '',
          skip,
          take
        })
        .toPromise();
      return query.data?.allChildren ?? undefined;
    },
    [organization, client]
  );

  const treeContext = useResourceGroupDataTree({
    dataSource: dataSource,
    orgId: organization?.id,
    /** a name such as "All" that represents the topmost _path_ that the user has access to.
     * I don't think we use this since we don't use the path atm, but it's required.
     */
    rootPathname: 'All',
    /**
     * @todo: I have asked lkpg if organization can change type to OrganizationWithLabel, since
     * label always seems to be included for organization, until then, we have to get it from organizations
     */
    rootFolderName:
      organizations?.find(org => organization && org.id === organization.id)?.label || ''
  });

  const {control: treeControl, selectedId} = useResourceGroupTreeControl({
    /** @todo use localStorage instance & configuration to remember user selection? */
    treeContext,
    viewConfig: {
      lang: language,
      /** @todo: align with DM and other apps */
      noMatchesPlaceholder: <div></div>,
      /** @todo: add translations */
      text: {searchPlaceholder: 'Search'}
    }
  });

  useEffect(() => {
    const details = treeContext.tree.getDetails(selectedId);
    if (!details) return;
    dispatch(setSelectedResourceGroup({id: details.id, name: details.name}));
  }, [selectedId, treeContext.tree, dispatch]);

  return (
    <Sidebar header="Folders">
      <ResourceGroupTreeView control={treeControl} />
    </Sidebar>
  );
};

export default ResourceGroupTree;
