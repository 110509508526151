import {PropsWithChildren, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Language,
  LanguageCode,
  PopulatedTopbar,
  PopulatedTopbarProvider
} from '@axteams-one/populated-topbar';
import {ThemeContext} from './FluentProviderWrapper';
import {appConfig} from './config';

export const Topbar = ({children}: PropsWithChildren) => {
  const {setTheme} = useContext(ThemeContext);
  const {i18n} = useTranslation();
  const onLanguageChanged = useCallback((lang: Language) => i18n.changeLanguage(lang), [i18n]);

  return (
    <PopulatedTopbarProvider
      env={appConfig.env !== 'dev' ? appConfig.env : 'stage'}
      region="eu"
      supportedLanguages={[LanguageCode.English]}
      acxApiKey={appConfig.acxApiKey}
      myApplication={{
        id: 'did'
      }}
      onThemeChanged={setTheme}
      onLanguageChanged={onLanguageChanged}
    >
      <PopulatedTopbar /**@todo: implement support for about, release notes, news and status */
        helpArea={{
          about: {}
        }}
      />
      {children}
    </PopulatedTopbarProvider>
  );
};
