/* eslint-disable */
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  Arn: {input: string; output: string};
  DateTime: {input: string; output: string};
  /**
   * A string based scalar type. It is used to denote registered
   * event types
   */
  EventType: {input: string; output: string};
  /**
   * An expression that sets how updates are scheduled.
   *
   * The value must conform to one of the following formats:
   *     manual: Means no automatic update
   *     window([0-23]-[0-23]): Specifies a window as 24-hour clock when an update can be performed.
   *         For instance, window(23-5) says that an update may occur between
   *         23:00 on the evening and 05:00 on the morning.
   *         Optionally, weekdays can be specified as the second argument.
   *         This will further constrain the update window to only occur on the days specified.
   *         If left unspecified, the update window will apply to all days.
   *         There are 2 formats:
   *         * The first format is a literal format, it is specified as following:
   *             window(0-1, [MONDAY, TUESDAY, SUNDAY])
   *         * The second format is a range format, it is specified as following:
   *             window(0-1, [MONDAY-FRIDAY])
   *         The values for days are: MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY
   */
  ScheduleExpression: {input: string; output: string};
  TimeZone: {input: string; output: string};
  /** Represents an URL */
  Url: {input: string; output: string};
};

/** AXIS Camera Application Platform */
export type Acap = {
  __typename?: 'Acap';
  /** The ID of the ACAP as found in the ACAP Portal */
  acapId: Scalars['Int']['output'];
  /** The name of the ACAP */
  name: Scalars['String']['output'];
  /** The current status of the ACAP */
  status: AcapStatus;
  /** Version of the ACAP */
  version: Scalars['String']['output'];
};

/** The different statuses of an ACAP. */
export enum AcapStatus {
  Running = 'RUNNING',
  Stopped = 'STOPPED'
}

/** Represents an access to a resource */
export type Access = {
  __typename?: 'Access';
  /** The Arn of the Access */
  arn: Scalars['Arn']['output'];
  /** The Principals that have been assigned to this Access */
  assignedPrincipals: Array<PrincipalReference>;
  /** When the Access was created */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the Access */
  description?: Maybe<Scalars['String']['output']>;
  /** The name of the Access */
  name: Scalars['String']['output'];
  /** Role given to Principals with this Access */
  role: AccessRole;
  /** The target to which this Access applies */
  target: AccessTargetReference;
};

/** Represents an access to a resource */
export type AccessArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessResponse = {
  __typename?: 'AccessResponse';
  /** The returned Accesses */
  access: Array<Access>;
  /** Pagination info */
  page: PaginationResponse;
};

export type AccessRole = {
  __typename?: 'AccessRole';
  /** ID of Application */
  applicationId?: Maybe<Scalars['String']['output']>;
  /** Name of Application */
  applicationName?: Maybe<Scalars['String']['output']>;
  /** ID of the Role */
  roleId: Scalars['ID']['output'];
  /** Name of the Role */
  roleName: Scalars['String']['output'];
};

/** A reference to an Access target. Can be either an Organization or Resource Group. */
export type AccessTargetReference = {
  __typename?: 'AccessTargetReference';
  /** ARN of the target */
  arn: Scalars['Arn']['output'];
  /** Name of the target */
  name: Scalars['String']['output'];
};

/** A reference to an Access target. Can be either an Organization or Resource Group. */
export type AccessTargetReferenceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AllChildrenInput = {
  /** The Organization or Resource Group where the query should start from. */
  arn: Scalars['Arn']['input'];
};

export type AllChildrenResponse = {
  __typename?: 'AllChildrenResponse';
  children: Array<ChildReference>;
  page: PaginationResponse;
};

/** Axis Camera device. */
export type AxisCamera = AxisDevice & {
  __typename?: 'AxisCamera';
  /** ACAPs installed on the AXIS Camera */
  acaps: Array<Acap>;
  /** Arn of the camera */
  arn: Scalars['Arn']['output'];
  /** Custom data */
  attributes?: Maybe<AxisDeviceAttributes>;
  /** A timestamp that represents when the camera was registered in a resource group */
  createdAt: Scalars['DateTime']['output'];
  firmware?: Maybe<Firmware>;
  model?: Maybe<AxisDeviceModel>;
  /** The onboarding state of the Axis camera */
  onboarding: AxisDeviceOnboarding;
  /** The serial number of the Axis camera */
  serial: Scalars['String']['output'];
  /** The timezone configured for the Axis camera */
  timeZone?: Maybe<Scalars['TimeZone']['output']>;
  updateConfiguration?: Maybe<UpdateConfiguration>;
};

/** Axis Camera device. */
export type AxisCameraArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generic Axis device */
export type AxisDevice = {
  /** ACAPs installed on the AXIS Device */
  acaps: Array<Acap>;
  /** Arn of the device */
  arn: Scalars['Arn']['output'];
  /** Custom data */
  attributes?: Maybe<AxisDeviceAttributes>;
  /** A timestamp that represents when the device was registered in a resource group */
  createdAt: Scalars['DateTime']['output'];
  firmware?: Maybe<Firmware>;
  model?: Maybe<AxisDeviceModel>;
  /** The onboarding state of the Axis device */
  onboarding: AxisDeviceOnboarding;
  /** The serial number of the Axis device */
  serial: Scalars['String']['output'];
  /** The timezone configured for the Axis device */
  timeZone?: Maybe<Scalars['TimeZone']['output']>;
  updateConfiguration?: Maybe<UpdateConfiguration>;
};

/** Generic Axis device */
export type AxisDeviceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AxisDeviceAttributes = {
  __typename?: 'AxisDeviceAttributes';
  /** Custom description of the device */
  description?: Maybe<Scalars['String']['output']>;
  /** Custom name of the device */
  name?: Maybe<Scalars['String']['output']>;
};

/** Information about the Axis Device model or product. */
export type AxisDeviceModel = {
  __typename?: 'AxisDeviceModel';
  /** PIA Product name of the device model. */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * PIA Product Variant ID of the device model.
   * This can be used to see an image for this device: https://www.axis.com/images/products/item-id.png.
   */
  piaItemId?: Maybe<Scalars['String']['output']>;
};

export type AxisDeviceOnboarding = {
  __typename?: 'AxisDeviceOnboarding';
  /**
   * An error message, if available, if the state is in any kind of error
   * @deprecated Use errorDescription - will be removed after 2024-03-31
   */
  error?: Maybe<Scalars['String']['output']>;
  /** A code corresponding to the type of error. */
  errorCode?: Maybe<AxisDeviceOnboardingErrorCode>;
  /** An error description, if available, if the state is in any kind of error */
  errorDescription?: Maybe<Scalars['String']['output']>;
  /** State of the device onboarding */
  state: AxisDeviceOnboardingState;
};

export enum AxisDeviceOnboardingErrorCode {
  AcapError = 'ACAP_ERROR',
  CurrentFirmwareTooOld = 'CURRENT_FIRMWARE_TOO_OLD',
  EnsureDefaultedDeviceError = 'ENSURE_DEFAULTED_DEVICE_ERROR',
  FirmwareTooOld = 'FIRMWARE_TOO_OLD',
  FirmwareUpgradeError = 'FIRMWARE_UPGRADE_ERROR',
  LatestFirmwareTooOld = 'LATEST_FIRMWARE_TOO_OLD',
  TimeOut = 'TIME_OUT',
  UnknownError = 'UNKNOWN_ERROR'
}

/** Represents the state of an Axis Device onboarding */
export enum AxisDeviceOnboardingState {
  /** The onboarding failed and is in an error state */
  Error = 'ERROR',
  /** The onboarding of the Axis device is finished */
  Finished = 'FINISHED',
  /** The Axis device is not registered with onboarding */
  NotRegistered = 'NOT_REGISTERED',
  /**
   * The Axis device has been registered but the onboarding
   * of the Axis device has not started yet
   */
  NotStarted = 'NOT_STARTED',
  /**
   * The onboarding of the Axis device has started
   * The button has been pressed
   */
  Started = 'STARTED'
}

export type ChildReference = {
  __typename?: 'ChildReference';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent: Scalars['ID']['output'];
};

export type ChildrenResponse = {
  __typename?: 'ChildrenResponse';
  /** The Resource Groups below this Resource Group/Organization */
  children: Array<ResourceGroupReference>;
  /** Pagination Info */
  page: PaginationResponse;
};

export type DevicesResponse = {
  __typename?: 'DevicesResponse';
  devices: Array<AxisDevice>;
  page: PaginationResponse;
};

/** An event subscription is */
export type EventSubscription = {
  /** The events that will trigger the call to the registered URL */
  eventTypes: Array<Scalars['EventType']['output']>;
  /** An ARN that represents the monitored resource */
  resourceArn: Scalars['Arn']['output'];
  /** An ARN that represents the registered event subscription */
  subscriptionArn: Scalars['Arn']['output'];
};

export type EventSubscriptions = {
  __typename?: 'EventSubscriptions';
  webhooks: Array<WebhookEventSubscription>;
};

export type Firmware = {
  __typename?: 'Firmware';
  /**
   * Firmware information for an Axis device.
   * Available after onboarding is finished.
   *
   * Limitation: If the device has been onboarded in the same Organization more than once, firmware information might be empty.
   */
  information: FirmwareInformation;
};

/** An AXIS OS firmware */
export type FirmwareInformation = {
  __typename?: 'FirmwareInformation';
  /** The pubAPI ID of the firmware version */
  id?: Maybe<Scalars['ID']['output']>;
  /** The firmware track (LTS2022, 8.50, 9.50, ...) */
  track?: Maybe<Scalars['String']['output']>;
  /** The firmware version string, typically like '1.2.34' */
  version: Scalars['String']['output'];
};

export type FirmwareVersion = {
  __typename?: 'FirmwareVersion';
  /** Link to release notes */
  releaseNotesUrl?: Maybe<Scalars['String']['output']>;
  /** The track, e.g. 'active', 'LTS-2020' */
  track: Scalars['String']['output'];
  /** The firmware version */
  value: Scalars['String']['output'];
};

export type IddSettings = {
  __typename?: 'IddSettings';
  /**
   * Indicates whether In-Device Diagnostics (IDD) logging has been enabled for this organization
   *
   * The default value for a new organization is 'false'
   */
  enabled: Scalars['Boolean']['output'];
};

export type Invitation = {
  __typename?: 'Invitation';
  createdAt: Scalars['DateTime']['output'];
  invitationArn: Scalars['Arn']['output'];
  invitee: Invitee;
  inviter: Inviter;
  organization: OrganizationInfo;
};

export type InvitationInvitationArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Invitee = {
  __typename?: 'Invitee';
  /** Email address of the invited MyAxis user. */
  email: Scalars['String']['output'];
  /** ARN of the Principal created for the invited MyAxis user. */
  principalArn: Scalars['Arn']['output'];
};

export type InviteePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Inviter = {
  __typename?: 'Inviter';
  /** Email address of the Principal that created the Invitation. */
  email: Scalars['String']['output'];
};

export type OnboardingSettings = {
  __typename?: 'OnboardingSettings';
  /**
   * The template for the organization
   *
   * Currently not possible to extract!
   */
  template?: Maybe<Scalars['ID']['output']>;
};

export type Organization = {
  __typename?: 'Organization';
  access: AccessResponse;
  allDevices: DevicesResponse;
  appliedAccesses: AccessResponse;
  arn: Scalars['Arn']['output'];
  children: ChildrenResponse;
  createdAt: Scalars['DateTime']['output'];
  eventSubscriptions: EventSubscriptions;
  invitations: Array<Invitation>;
  name?: Maybe<Scalars['String']['output']>;
  principals: PrincipalsResponse;
  settings?: Maybe<OrganizationSettings>;
};

export type OrganizationAccessArgs = {
  page?: InputMaybe<PaginationInput>;
};

export type OrganizationAllDevicesArgs = {
  page?: InputMaybe<PaginationInput>;
};

export type OrganizationAppliedAccessesArgs = {
  page?: InputMaybe<PaginationInput>;
};

export type OrganizationChildrenArgs = {
  page?: InputMaybe<PaginationInput>;
};

export type OrganizationPrincipalsArgs = {
  page?: InputMaybe<PaginationInput>;
};

export type OrganizationInfo = {
  __typename?: 'OrganizationInfo';
  arn: Scalars['Arn']['output'];
  /** Description of the Organization at the time the Invitation was created. */
  description?: Maybe<Scalars['String']['output']>;
  /** Name of the Organization at the time the Invitation was created. */
  name: Scalars['String']['output'];
};

export type OrganizationInfoArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationSettings = {
  __typename?: 'OrganizationSettings';
  idd: IddSettings;
  onboarding: OnboardingSettings;
};

export type PaginationInput = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * This type is returned in the response object of all paginated listings. It shows the
 * values used when producing the result. If no pagination input was given the default
 * values will be used.
 */
export type PaginationResponse = {
  __typename?: 'PaginationResponse';
  skip: Scalars['Int']['output'];
  take: Scalars['Int']['output'];
};

export type Principal = {
  /** Accesses assigned to this Principal. Returns a maximum of 100. */
  accesses?: Maybe<Array<Access>>;
  arn: Scalars['Arn']['output'];
  createdAt: Scalars['DateTime']['output'];
  properties: PrincipalProperties;
};

export type PrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Properties on a Principal that applies to the entire Organization */
export type PrincipalProperties = {
  __typename?: 'PrincipalProperties';
  /** Allows the Principal to manage Accesses in the Organization. */
  isAccessAdmin: Scalars['Boolean']['output'];
  /** Allows viewing Accesses in the Organization */
  isAccessViewer: Scalars['Boolean']['output'];
  /** Owner allows the Principal to do all AXIS IAM operations, including archiving the Organization. */
  isOwner: Scalars['Boolean']['output'];
  /** Allows management of Principals in the Organization, such as creating invitations. */
  isPrincipalAdmin: Scalars['Boolean']['output'];
  /** Allows listing Principals in the Organization */
  isPrincipalViewer: Scalars['Boolean']['output'];
};

/** A shallow representation of a Principal */
export type PrincipalReference = {
  __typename?: 'PrincipalReference';
  /** The Arn of the Principal. */
  arn: Scalars['Arn']['output'];
};

/** A shallow representation of a Principal */
export type PrincipalReferenceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PrincipalsResponse = {
  __typename?: 'PrincipalsResponse';
  page: PaginationResponse;
  principals?: Maybe<Array<Principal>>;
};

export type Query = {
  __typename?: 'Query';
  /**
   * Query for all Resource Groups in an Organization.
   * This will retrieve Resource Groups of all levels, where Access permits it.
   *
   * In order to represent a tree, the client must build it using the "id" and "parent" fields.
   */
  allChildren?: Maybe<AllChildrenResponse>;
  allDevices?: Maybe<RegisteredDevicesResponse>;
  /** This query will return an organization from its ARN. */
  organization?: Maybe<Organization>;
};

export type QueryAllChildrenArgs = {
  input: AllChildrenInput;
  page?: InputMaybe<PaginationInput>;
};

export type QueryAllDevicesArgs = {
  organizationArn: Scalars['Arn']['input'];
};

export type QueryOrganizationArgs = {
  organizationArn: Scalars['Arn']['input'];
};

export type RegisteredDevicesResponse = {
  __typename?: 'RegisteredDevicesResponse';
  organization?: Maybe<AllDevices>;
};

export type RegsiteredDeviceResponse = {
  __typename?: 'RegsiteredDeviceResponse';
  /** String to be replaced by Arn type */
  arn?: Maybe<Scalars['Arn']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  onboarding?: Maybe<AxisDeviceOnboarding>;
  serial?: Maybe<Scalars['String']['output']>;
};

/** A shallow representation, or reference, of a Resource Group. */
export type ResourceGroupReference = {
  __typename?: 'ResourceGroupReference';
  /** The ARN of the ResourceGroup */
  arn: Scalars['Arn']['output'];
  /** A timestamp set to the time the ResourceGroup was created */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the ResourceGroup */
  description?: Maybe<Scalars['String']['output']>;
  /** The name of the ResourceGroup */
  name: Scalars['String']['output'];
};

/** A shallow representation, or reference, of a Resource Group. */
export type ResourceGroupReferenceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateConfiguration = {
  __typename?: 'UpdateConfiguration';
  /** Specifies when firmware and Acaps will be updated */
  schedule: Scalars['ScheduleExpression']['output'];
};

/** A Webhook event subscription */
export type WebhookEventSubscription = EventSubscription & {
  __typename?: 'WebhookEventSubscription';
  /** The events that will trigger the call to the registered URL */
  eventTypes: Array<Scalars['EventType']['output']>;
  /** An ARN that represents the monitored resource */
  resourceArn: Scalars['Arn']['output'];
  /** An ARN that represents the registered event subscription */
  subscriptionArn: Scalars['Arn']['output'];
  /**
   * The registered URL that will be called when the event happens
   * on the resource
   */
  url: Scalars['Url']['output'];
};

/** A Webhook event subscription */
export type WebhookEventSubscriptionResourceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A Webhook event subscription */
export type WebhookEventSubscriptionSubscriptionArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AllDevices = {
  __typename?: 'allDevices';
  devices?: Maybe<Array<Maybe<RegsiteredDeviceResponse>>>;
};

export type AllDevicesQueryVariables = Exact<{
  organizationArn: Scalars['Arn']['input'];
}>;

export type AllDevicesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    allDevices: {
      __typename?: 'DevicesResponse';
      devices: Array<{
        __typename?: 'AxisCamera';
        arn: string;
        serial: string;
        createdAt: string;
        onboarding: {
          __typename?: 'AxisDeviceOnboarding';
          state: AxisDeviceOnboardingState;
          error?: string | null;
        };
        acaps: Array<{
          __typename?: 'Acap';
          name: string;
          status: AcapStatus;
          version: string;
          acapId: number;
        }>;
        model?: {__typename?: 'AxisDeviceModel'; name?: string | null} | null;
        firmware?: {
          __typename?: 'Firmware';
          information: {__typename?: 'FirmwareInformation'; version: string};
        } | null;
      }>;
    };
  } | null;
};

export type AllResourceGroupsQueryVariables = Exact<{
  organizationArn: Scalars['Arn']['input'];
}>;

export type AllResourceGroupsQuery = {
  __typename?: 'Query';
  allChildren?: {
    __typename?: 'AllChildrenResponse';
    children: Array<{__typename?: 'ChildReference'; id: string; name: string; parent: string}>;
    page: {__typename?: 'PaginationResponse'; skip: number; take: number};
  } | null;
};

export const AllDevicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AllDevices'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'organizationArn'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Arn'}}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'organization'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'organizationArn'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'organizationArn'}}
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'allDevices'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'devices'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'arn'}},
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {kind: 'Name', value: 'AxisCamera'}
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'arn'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'serial'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'onboarding'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'error'}}
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'acaps'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'version'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'acapId'}}
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'model'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}}
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'firmware'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'information'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'version'}
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AllDevicesQuery, AllDevicesQueryVariables>;
export const AllResourceGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AllResourceGroups'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'organizationArn'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Arn'}}}
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'allChildren'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'arn'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'organizationArn'}}
                    }
                  ]
                }
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'page'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'take'},
                      value: {kind: 'IntValue', value: '4000'}
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'skip'},
                      value: {kind: 'IntValue', value: '0'}
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'children'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'parent'}}
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'page'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'skip'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'take'}}
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AllResourceGroupsQuery, AllResourceGroupsQueryVariables>;
