import {
  makeStyles,
  Button,
  tokens,
  TagGroup,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  Input,
  Card,
  Text,
  InteractionTagPrimary,
  InteractionTagSecondary,
  InteractionTag,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  useOverflowMenu,
  useIsOverflowItemVisible,
  MenuItem,
  OverflowItem,
  Overflow,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Field
} from '@fluentui/react-components';
import {Tag} from '@fluentui/react-components';
import {Add16Regular} from '@fluentui/react-icons';
import {useState, KeyboardEvent, useCallback} from 'react';

const useStyles = makeStyles({
  tagPicker: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalM,
    alignItems: 'center'
  },
  addTag: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalM,
    alignItems: 'flex-start'
  },
  tagGroup: {
    display: 'flex'
  },
  container: {
    overflow: 'hidden',
    height: 'fit-content',
    maxWidth: '350px',
    boxSizing: 'border-box'
  }
});

/**
 * @todo:
 * get user permissions from BE
 * assign/unassign tag
 * create/delete tag
 */
const canAssignUnassignTag = true;
const canCreateDeleteTag = true;

interface ScenarioTagPickerProps {
  options: string[];
  selectedOptions: string[];
  onAdd: (tagName: string) => void;
  onDelete: (tagName: string) => void;
  onUnassign: (tagName: string) => void;
  onAssign: (tagName: string) => void;
  scenarioId: number;
}

enum NewTagState {
  EMPTY_TAG = 'Tag must be at least 1 character.',
  ALREADY_EXISTS = 'This tag already exists.',
  VALID = ''
}

export const ScenarioTagPicker = ({
  options,
  onAdd,
  onDelete,
  onAssign,
  onUnassign,
  selectedOptions,
  scenarioId
}: ScenarioTagPickerProps) => {
  const styles = useStyles();
  const [newTag, setNewTag] = useState<{value: string; status: NewTagState} | undefined>();

  const addNewTag = useCallback(() => {
    if (newTag === undefined) {
      setNewTag({
        value: '',
        status: NewTagState.EMPTY_TAG
      });
      return;
    }
    if (newTag?.status !== NewTagState.VALID) {
      return;
    }
    onAdd(newTag.value);
    setNewTag(undefined);
  }, [newTag, setNewTag, onAdd]);

  return (
    <div className={styles.tagPicker}>
      <div className={styles.container}>
        <Overflow minimumVisible={2}>
          <TagGroup className={styles.tagGroup} onDismiss={(_, value) => onUnassign(value.value)}>
            {selectedOptions.map(option => (
              <OverflowItem key={option} id={option}>
                <Tag
                  data-testid={'assigned-tag-' + option}
                  appearance="outline"
                  shape="rounded"
                  value={option}
                  dismissible={canAssignUnassignTag}
                >
                  {option}
                </Tag>
              </OverflowItem>
            ))}
            <OverflowMenu items={selectedOptions} />
          </TagGroup>
        </Overflow>
      </div>
      <Popover>
        <PopoverTrigger>
          {canCreateDeleteTag ? (
            <Button
              data-testid={'open-tag-picker-button-' + scenarioId}
              size="small"
              icon={<Add16Regular />}
            />
          ) : undefined}
        </PopoverTrigger>

        <PopoverSurface tabIndex={-1} data-testid={'tag-picker-' + scenarioId}>
          <div className={styles.container}>
            <Card size="small" appearance="subtle">
              <Text weight="bold">Add tags</Text>
              <div className={styles.addTag}>
                <Field validationMessage={newTag?.status}>
                  <Input
                    maxLength={10}
                    value={newTag?.value || ''}
                    onChange={(_, data) =>
                      setNewTag({
                        value: data.value,
                        status:
                          options.includes(data.value) || selectedOptions.includes(data.value)
                            ? NewTagState.ALREADY_EXISTS
                            : NewTagState.VALID
                      })
                    }
                    onBlur={() => {
                      if (!newTag?.value) {
                        setNewTag({
                          value: '',
                          status: NewTagState.EMPTY_TAG
                        });
                      }
                    }}
                    onKeyDown={(e: KeyboardEvent) => {
                      if (e.key === 'Enter') {
                        addNewTag();
                      }
                    }}
                  />
                </Field>
                <Button
                  onClick={() => {
                    addNewTag();
                  }}
                >
                  Create
                </Button>
              </div>

              <Overflow minimumVisible={2}>
                <TagGroup className={styles.tagGroup}>
                  {options.map(option => (
                    <OverflowItem key={option} id={option}>
                      <InteractionTag data-testid={'unassigned-tag-' + option} value={option}>
                        <InteractionTagPrimary
                          data-testid="assign-button"
                          onClick={() => onAssign(option)}
                          hasSecondaryAction
                        >
                          {option}
                        </InteractionTagPrimary>
                        <DeleteDialog onDelete={() => onDelete(option)} />
                      </InteractionTag>
                    </OverflowItem>
                  ))}
                  <OverflowMenu items={options} onTagClick={onAssign} onDelete={onDelete} />
                </TagGroup>
              </Overflow>
            </Card>
          </div>
        </PopoverSurface>
      </Popover>
    </div>
  );
};

interface OverflowMenuProps {
  items: string[];
  onTagClick?: (opt: string) => void;
  onDelete?: (opt: string) => void;
}

const OverflowMenu = ({items, onDelete, onTagClick}: OverflowMenuProps) => {
  const {ref, isOverflowing, overflowCount} = useOverflowMenu<HTMLButtonElement>();
  if (!isOverflowing) {
    return null;
  }

  return (
    <InteractionTag>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <InteractionTagPrimary ref={ref}>{`+${overflowCount}`}</InteractionTagPrimary>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            {items.map(item => (
              <OverflowMenuItem
                key={item}
                value={item}
                onTagClick={onTagClick}
                onDelete={onDelete}
              />
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    </InteractionTag>
  );
};
type OverflowMenuItemProps = {
  value: string;
  onTagClick?: (opt: string) => void;
  onDelete?: (opt: string) => void;
};

const OverflowMenuItem = ({value, onTagClick, onDelete}: OverflowMenuItemProps) => {
  const isVisible = useIsOverflowItemVisible(value);

  if (isVisible) {
    return null;
  }

  return (
    <MenuItem persistOnClick>
      {onDelete && onTagClick ? (
        <InteractionTag value={value}>
          <InteractionTagPrimary onClick={() => onTagClick(value)} hasSecondaryAction>
            {value}
          </InteractionTagPrimary>
          <DeleteDialog onDelete={() => onDelete(value)} />
        </InteractionTag>
      ) : (
        <Tag appearance="outline" shape="rounded" value={value} dismissible={canAssignUnassignTag}>
          {value}
        </Tag>
      )}
    </MenuItem>
  );
};

interface DeleteDialogProps {
  onDelete: () => void;
}
const DeleteDialog = ({onDelete}: DeleteDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <InteractionTagSecondary data-testid="delete-button" />
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Delete tag?</DialogTitle>
          <DialogContent>
            Are you sure you want to delete the tag from the inventory? Once deleted it will be
            removed from all assigned scenarios.
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Cancel</Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={() => onDelete()}>
              Delete
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
