import {PropsWithChildren} from 'react';

import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground2,
    boxShadow: tokens.shadow4,
    display: 'flex',
    rowGap: tokens.spacingHorizontalS,
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: tokens.spacingVerticalXXXL,
    paddingRight: tokens.spacingVerticalXXXL,
    paddingTop: tokens.spacingVerticalXXL,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: `${tokens.borderRadiusXLarge} 0 0 0`,
    ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralShadowKeyLighter)
  }
});

const PageContent = ({children}: PropsWithChildren) => {
  const styles = useStyles();

  return <div className={styles.wrapper}>{children}</div>;
};

export default PageContent;
