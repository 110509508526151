import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {UseQueryState} from 'urql';
import {AxisDevice, AllDevicesQuery} from '../api/__generated__';
import {ActionData} from '../types';

type Nullable<T> = T | null;

// Define a type for the slice state
interface Devices {
  readonly status: string;
  readonly allDevices: Partial<AxisDevice>[];
  readonly selectedDevices: Partial<AxisDevice>[];
  readonly actions: ActionData[];
  readonly error: Nullable<string> | undefined;
}

// Define the initial state using that type
const devicesInitialState: Devices = {
  status: 'idle',
  allDevices: [],
  selectedDevices: [],
  actions: [],
  error: null
};

export const fetchDevices = createAsyncThunk(
  'data/fetchDevices',
  async (result: UseQueryState<AllDevicesQuery, {organizationArn: string}>) => {
    if (result.fetching) {
      // Handle the loading state
      return {status: 'loading'};
    }

    if (result.error && !result.data) {
      // Handle the error state
      return {status: 'failed', error: JSON.stringify(result.error)};
    }

    // Handle the success state and return the data
    return {status: 'succeeded', data: result.data};
  }
);

export const devicesSlice = createSlice({
  name: 'devices',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: devicesInitialState,
  reducers: {
    addAllDevices: (state, action) => {
      state.allDevices = action.payload;
    },
    addSelectedDevices: (state, action) => {
      state.selectedDevices = action.payload;
    },
    addActions: (state, action) => {
      state.actions = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDevices.fulfilled, (state, action) => {
        state.status = action.payload.status;
        if (action.payload.status === 'succeeded' && action.payload.data) {
          const devices = action.payload?.data?.organization?.allDevices.devices;
          const registeredDevicesList: Partial<AxisDevice>[] = (devices || []).filter(
            (dev: AxisDevice) => dev.onboarding.state === 'FINISHED'
          );
          state.allDevices = registeredDevicesList;
        }
        if (action.payload.status === 'failed') {
          state.error = action.payload.error;
        }
      })
      .addCase(fetchDevices.rejected, state => {
        state.status = 'failed';
      });
  }
});

export const {addAllDevices, addSelectedDevices, addActions} = devicesSlice.actions;
export default devicesSlice.reducer;
