import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
  Text,
  makeStyles,
  shorthands,
  tokens
} from '@fluentui/react-components';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useAuth} from '@axteams-one/auth-provider';

import PageHeader from '../../components/PageHeader';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {Acap, AllDevicesDocument, AxisDevice} from '../../api/__generated__';

import {useQuery} from 'urql';
import Loader from '../Loader';
import {ACAPS, ActionData, ActionDisplayName, ActionGroup} from '../../types';
import useToasts from '../../hooks/useToasts';
import {useToken} from '../../hooks/useToken';
import {addActions, fetchDevices} from '../../reducers/devicesSlice';
import {fetchActions, fetchImg} from '../../fetcher';
import {addDeviceDetails} from '../../reducers/deviceDetailsSlice';
import ActionCard from './ActionCard';
import ViewOptions from '../Devices/ViewOptions';
import ResourceGroupTree from '../../components/ResourceGroupTree';
import PageContent from '../../components/PageContent';

const useStyles = makeStyles({
  title: {
    ...shorthands.margin(0, 0, 0, 0),
    color: tokens.colorNeutralForeground3
  },
  wrapper: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    ...shorthands.overflow('hidden')
  },
  createAction: {
    display: 'flex',
    paddingTop: tokens.spacingVerticalXL,
    paddingBottom: tokens.spacingHorizontalXL
  },
  actionCards: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL
  }
});

interface Action {
  readonly thumbnails: string[];
  readonly serial: string;
}

const Actions = () => {
  const {status: authStatus} = useAuth();
  const {dispatchAppToast} = useToasts();
  const token = useToken();
  const navigate = useNavigate();
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const {organization} = usePopulatedTopbarValues();

  const {
    allDevices,
    actions: allActions,
    status: devicesStatus,
    error
  } = useAppSelector(state => state.devicesSlice);
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);

  const [actions, setActions] = useState<Action[]>([]);
  const [actionGroups, setActionGroups] = useState<ActionGroup>({});
  const [isListview, setisListView] = useState(true);

  const [deviceList] = useQuery({
    pause: authStatus > 0 || !organization?.arn,
    query: AllDevicesDocument,
    variables: {organizationArn: organization?.arn || ''}
  });

  useEffect(() => {
    const groupedActions = groupActions(allActions);
    const filteredGroupActions = Object.fromEntries(
      Object.entries(groupedActions).filter(([key, actions]) =>
        actions.some(action => action.ARN.includes(selectedResourceGroup.id))
      )
    );
    setActionGroups(filteredGroupActions);
  }, [allActions, selectedResourceGroup]);

  const getUniqueSerialsCount = (devices: ActionData[]) => {
    const uniqueSerials = new Set(devices.map((device: ActionData) => device.Serial));
    return uniqueSerials.size;
  };

  const onClickActions = () => {
    navigate('/actions/createAction');
  };

  const groupActions = (actions: ActionData[]) => {
    const groupedActions: ActionGroup = {};
    actions.forEach((action: ActionData) => {
      const actionName = action.ActionDetails.Action_Name as keyof typeof ActionDisplayName;
      const displayName = ActionDisplayName[actionName];
      //TODO: Need to fetch dynamic card headers
      const title =
        displayName === ActionDisplayName.occupancyInArea
          ? 'Cashier counters'
          : displayName === ActionDisplayName.crosslinecounting
            ? 'Entrance counters'
            : 'Exit counters';
      const actionWithDetails = {...action, title};
      if (!groupedActions[actionName]) {
        groupedActions[actionName] = [actionWithDetails];
      } else {
        groupedActions[actionName].push(actionWithDetails);
      }
    });
    return groupedActions;
  };

  const getGeneralInfoForAllDevices = useCallback(
    (devices: Partial<AxisDevice>[]) => {
      const fetchPromises = devices.map(async device => {
        const aiSupported =
          device.acaps?.find((acap: Acap) => acap.name === ACAPS.AOA)?.status === 'RUNNING';

        if (token && organization?.arn) {
          if (device?.serial) {
            const response = await fetchImg(token, organization.arn, device.serial);
            return {
              serial: device.serial,
              img: response,
              aiSupported
            };
          }
        }
      });
      const devicesProps = Promise.all(fetchPromises);
      return devicesProps;
    },
    [token, organization]
  );

  useEffect(() => {
    if (allDevices.length) {
      getGeneralInfoForAllDevices(allDevices).then(res => dispatch(addDeviceDetails(res)));
    }
  }, [allDevices, getGeneralInfoForAllDevices, dispatch]);

  useEffect(() => {
    dispatch(fetchDevices(deviceList));
  }, [deviceList, dispatch]);

  // Fetch devices
  useEffect(() => {
    fetchActions().then((actions: ActionData[]) => {
      dispatch(addActions(actions));
      const groupedActions: ActionGroup = groupActions(actions);
      setActionGroups(groupedActions);
    });
  }, [deviceList, dispatch]);

  useEffect(() => {
    if (error) {
      dispatchAppToast({title: 'error', intent: 'error', message: error});
    }
  }, [error, dispatchAppToast]);

  useEffect(() => {
    if (!token || !organization?.arn || !actionGroups) return;
    const fetchPromises = allDevices
      .filter(device => device && device.serial)
      .map(device => fetchImg(token, organization.arn, device.serial as string));

    Promise.all(fetchPromises).then(results => {
      const blobs = results.filter(result => result !== undefined) as string[];

      const actionsWithBlobsAndSerials = blobs.map((blob, index) => ({
        thumbnails: [blob, blob],
        serial: allDevices[index]?.serial || ''
      }));

      setActions(actionsWithBlobsAndSerials);
    });
  }, [token, organization, allDevices, actionGroups]);

  if (devicesStatus !== 'succeeded') {
    return <Loader />;
  }

  return (
    <>
      <ResourceGroupTree />
      <PageContent>
        <PageHeader title="Actions" />
        <ViewOptions isListView={isListview} onChangeView={setisListView} />
        <div className={styles.createAction}>
          <Button appearance="primary" onClick={onClickActions}>
            Create
          </Button>
        </div>
        <div>
          <Accordion collapsible>
            {Object.keys(actionGroups).map((actionName, index) => (
              <AccordionItem key={index} value={actionName}>
                <AccordionHeader size="extra-large">
                  <Text as="h4" weight="semibold" className={styles.title}>
                    {`${ActionDisplayName[actionName as keyof typeof ActionDisplayName]} (${actionGroups[actionName].length})`}
                  </Text>
                </AccordionHeader>
                <AccordionPanel>
                  <div className={styles.actionCards}>
                    {actions.length !== 0 &&
                      actionGroups[actionName]?.map((device: ActionData, index) => (
                        <ActionCard
                          key={`${device.ID}_${index}`}
                          title={device.title}
                          description={`${getUniqueSerialsCount(actionGroups[actionName])} device(s)`}
                          thumbnails={
                            actions.find(actionCard => actionCard.serial === device.Serial)
                              ?.thumbnails || actions[0].thumbnails
                          }
                        />
                      ))}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </PageContent>
    </>
  );
};

export default Actions;
