import {ReactNode} from 'react';
import {
  Body1Strong,
  Button,
  Caption1,
  Card,
  CardHeader,
  makeStyles,
  shorthands,
  tokens
} from '@fluentui/react-components';
import {Lightbulb24Regular, MoreHorizontal20Regular} from '@fluentui/react-icons';

interface ActionCardProps {
  readonly title: ReactNode;
  readonly description?: ReactNode;
  readonly thumbnails: string[];
}

const useStyles = makeStyles({
  wrapper: {
    width: '300px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  actionCard: {
    zIndex: 9
  },
  thumbnails: {
    ...shorthands.borderRadius(tokens.borderRadiusLarge, tokens.borderRadiusLarge, 0, 0),
    ...shorthands.overflow('hidden'),
    height: '170px',
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  thumbnail: {
    ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke1),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    ...shorthands.overflow('hidden'),
    width: '240px',
    height: '180px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:nth-child(1)': {
      zIndex: 3,
      transform: 'translate(-10px, 20px)'
    },
    '&:nth-child(2)': {
      zIndex: 2,
      transform: 'translate(0, 10px)'
    },
    '&:nth-child(3)': {
      zIndex: 1,
      transform: 'translate(10px, 0)'
    }
  },
  thumbnailImg: {
    width: '100%'
  }
});

const ActionCard = ({title, description, thumbnails}: ActionCardProps) => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.thumbnails}>
        {thumbnails.map((tn, index) => (
          <div key={`${tn}_${index}`} className={styles.thumbnail}>
            <img className={styles.thumbnailImg} src={tn} alt="Device preview" />
          </div>
        ))}
      </div>
      <Card orientation="horizontal" className={styles.actionCard}>
        <CardHeader
          header={<Body1Strong>{title}</Body1Strong>}
          image={<Lightbulb24Regular />}
          {...(description && {description: <Caption1>{description}</Caption1>})}
          action={
            <Button
              appearance="transparent"
              icon={<MoreHorizontal20Regular />}
              aria-label="More options"
            />
          }
        />
      </Card>
    </div>
  );
};

export default ActionCard;
