import {useCallback, useMemo, useState} from 'react';
import {Button, makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {useNavigate} from 'react-router-dom';

import {AxisDevice} from '../../../api/__generated__';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import PageHeader from '../../../components/PageHeader';
import ViewOptions from '../../Devices/ViewOptions';
import DevicePanel from '../../Devices/DevicePanel';
import DevicesListView from '../../Devices/DevicesListView';
import DevicesGridView from '../../Devices/DevicesGridView';
import {addSelectedDevices} from '../../../reducers/devicesSlice';
import ConfigureActionsPanel from '../ConfigureActionsPanel';
import ResourceGroupTree from '../../../components/ResourceGroupTree';
import PageContent from '../../../components/PageContent';

const useStyles = makeStyles({
  image: {
    width: '100%'
  },
  footer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    ...shorthands.padding(tokens.spacingVerticalM)
  }
});

const CreateAction = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {selectedDevices, allDevices} = useAppSelector(state => state.devicesSlice);

  const [isListview, setisListView] = useState(true);
  const [isOpenConfigurePanel, setIsOpenConfigurePanel] = useState(false);
  const [openSidebarSerial, setOpenSidebarSerial] = useState<string | boolean>();

  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);

  const onClosePanel = (panel: string) => {
    if (panel === 'configuration') {
      setIsOpenConfigurePanel(false);
      navigate('/actions');
    } else {
      setOpenSidebarSerial(false);
    }
  };

  const devicesSelectHandler = useCallback(
    (selectedDevicesList: Partial<AxisDevice>[]) => {
      dispatch(addSelectedDevices(selectedDevicesList));
    },
    [dispatch]
  );

  const filteredDevices = useMemo(
    () =>
      allDevices.filter(
        device =>
          selectedResourceGroup.id === '' ||
          (device.arn && device.arn.includes(selectedResourceGroup.id))
      ),
    [allDevices, selectedResourceGroup]
  );

  const onClickConfigure = () => {
    setIsOpenConfigurePanel(true);
    navigate('/actions/createAction/selectTemplate');
  };

  return (
    <>
      <ResourceGroupTree />
      <PageContent>
        <PageHeader title="Actions" />
        <ViewOptions isListView={isListview} onChangeView={setisListView} />
        <DevicePanel
          device={allDevices.find(device => device.serial === openSidebarSerial)}
          isOpen={!!openSidebarSerial}
          onClose={() => onClosePanel('device')}
        />
        <ConfigureActionsPanel
          isOpen={isOpenConfigurePanel}
          onClose={() => onClosePanel('configuration')}
        />
        {isListview ? (
          <DevicesListView
            devices={filteredDevices}
            selectedDevices={selectedDevices}
            devicesSelectHandler={devicesSelectHandler}
            setOpenSidebar={setOpenSidebarSerial}
          />
        ) : (
          <DevicesGridView
            devices={filteredDevices}
            selectedDevices={selectedDevices}
            devicesSelectHandler={devicesSelectHandler}
            setOpenSidebar={setOpenSidebarSerial}
          />
        )}
        <div className={styles.footer}>
          <Button
            appearance="primary"
            disabled={!selectedDevices.length}
            onClick={onClickConfigure}
          >
            Configure
          </Button>
        </div>
      </PageContent>
    </>
  );
};

export default CreateAction;
