import {useNavigate, useLocation} from 'react-router-dom';
import {useCallback, useState} from 'react';
import {makeStyles, tokens, Button, Card, shorthands} from '@fluentui/react-components';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';

import {useToken} from '../../../../hooks/useToken';
import DeviceScenarioConfiguration from './DeviceScenarioConfiguration';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {
  addAoAScenario,
  DeviceGeneralDetails,
  setCommonConfiguration
} from '../../../../reducers/deviceDetailsSlice';
import {DeviceDetailsState} from '../../../../reducers/deviceDetailsSlice';
import ConfigureConfirmationModal from './ConfigureConfirmationModal';

const useStyles = makeStyles({
  deviceHeaderTitle: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightBold
  },
  deviceHeader: {
    alignItems: 'center'
  },
  navFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    ...shorthands.padding(tokens.spacingVerticalM),
    '& button': {
      ...shorthands.margin(0, tokens.spacingHorizontalM)
    }
  }
});

const ScenarioConfiguration = () => {
  const token = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const {organization} = usePopulatedTopbarValues();
  const dispatch = useAppDispatch();
  const styles = useStyles();

  const [stepCount, setStepCount] = useState(1);

  const {selectedDevices} = useAppSelector(state => state.devicesSlice);
  const {devicesGeneralDetails}: DeviceDetailsState = useAppSelector(
    state => state.deviceDetailsSlice
  );

  const onConfigure = useCallback(() => {
    if (token && organization) {
      // This is for Cameras that supports AI. We will need a seperate slice to add scenarios to non AI cameras.
      devicesGeneralDetails.forEach((deviceInfo: DeviceGeneralDetails) => {
        if (deviceInfo?.newScenarioTemplate) {
          dispatch(
            addAoAScenario({
              serial: deviceInfo.serial,
              scenario: deviceInfo.newScenarioTemplate
            })
          );
        }
        dispatch(setCommonConfiguration({objectClassifications: []}));
        const path = location.pathname.includes('/actions')
          ? 'actions/createAction'
          : 'configuration';
        navigate(`/${path}/setEvents`);

        // TODO: Commented for internal test purpose as each camera can have only max 10 scenarios. Enable later.
        // if (deviceInfo?.aiSupported) {
        //   devicesAoADetails.forEach(deviceAoAInfo => {
        //     setNewAoaConfig(token, organization?.arn, deviceInfo.serial, {
        //       ...deviceAoAInfo.configuration,
        //       scenarios: [...deviceAoAInfo?.configuration.scenarios, deviceInfo.newScenarioTemplate]
        //     }).then(() => {
        //       dispatch(
        //         addAoAScenario({
        //           serial: deviceInfo.serial,
        //           scenario: deviceInfo.newScenarioTemplate
        //         })
        //       );
        //       dispatch(resetDeviceCommonDetails());
        //       navigate('/configuration/setEvents');
        //     });
        //   });
        // }
      });
    }
  }, [token, organization, devicesGeneralDetails, location.pathname, navigate, dispatch]);

  const onPrevious = useCallback(() => setStepCount(index => index - 1), []);

  const onNext = useCallback(() => setStepCount(index => index + 1), []);

  return (
    <>
      <Card className={styles.deviceHeader} appearance="subtle">
        <div className={styles.deviceHeaderTitle}>
          Device {stepCount}/{selectedDevices.length}
        </div>
        <div>{selectedDevices.at(stepCount - 1)?.serial}</div>
      </Card>
      <DeviceScenarioConfiguration deviceSerial={selectedDevices.at(stepCount - 1)?.serial || ''} />
      <div className={styles.navFooter}>
        {selectedDevices.length > 1 && stepCount !== 1 && (
          <Button appearance="primary" onClick={onPrevious}>
            Previous
          </Button>
        )}
        {stepCount === selectedDevices.length ? (
          <ConfigureConfirmationModal onConfigure={onConfigure} />
        ) : (
          <Button appearance="primary" onClick={onNext}>
            Next
          </Button>
        )}
      </div>
    </>
  );
};

export default ScenarioConfiguration;
