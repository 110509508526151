import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  Tab,
  TabList,
  tokens
} from '@fluentui/react-components';
import {embedDashboard} from '@superset-ui/embedded-sdk';
import {fetchDashboardData, fetchGuestToken, GuestTokenErrors} from '../../fetcher';
import {useEffect, useState} from 'react';
import {DashboardInfo, GuestTokenPayload} from '../../types';
import {useSearchParams} from 'react-router-dom';
import {useOpenTelemetry} from '@axteams-one/opentelemetry-js-react';
import {bundleIcon, QuestionCircle24Filled, QuestionCircle24Regular} from '@fluentui/react-icons';

const useStyles = makeStyles({
  wrapper: {
    flexGrow: 1,
    background: 'white'
  },
  supersetcontainer: {
    height: '100%',
    display: 'flex'
  },
  header: {
    // Attempt to mimic superset dashboard header
    color: '#333',
    fontFamily: 'Inter,Helvetica,Arial',
    lineHeight: 1.4,
    fontSize: '21px',
    fontWeight: 600
  },
  noDevicesMessage: {
    paddingLeft: tokens.spacingHorizontalXXL
  },
  topBar: {
    paddingLeft: tokens.spacingHorizontalXXL,
    paddingRight: tokens.spacingHorizontalXXL,
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const HelpAreaIcon = bundleIcon(QuestionCircle24Filled, QuestionCircle24Regular);

const Superset = () => {
  const styles = useStyles();
  const [selectedDashboardUuid, setSelectedDashboardUuid] = useState<DashboardInfo>();
  const [availableDashboards, setAvailableDashboards] = useState<DashboardInfo[]>([]);
  const [guestTokenError, setGuestTokenError] = useState<GuestTokenErrors>();
  const [searchParams] = useSearchParams();
  const [organizationArn, setOrganizationArn] = useState<string>();
  const [resourceGroup, setResourceGroup] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const openTelemetry = useOpenTelemetry();

  useEffect(() => {
    setOrganizationArn(searchParams.get('organizationArn') || '');
    setResourceGroup(searchParams.get('resourceGroup') || '');
    setLanguage(searchParams.get('language') || '');
  }, [searchParams]);

  useEffect(() => {
    if (!organizationArn) {
      return;
    }
    fetchDashboardData({organizationArn: organizationArn}, openTelemetry).then(dashboardInfo => {
      const dashboardInfoSorted = dashboardInfo.sort((a, b) => a.name.localeCompare(b.name));
      if (dashboardInfoSorted[0]) {
        setSelectedDashboardUuid(dashboardInfoSorted[0]);
      }
      setAvailableDashboards(dashboardInfoSorted);
    });
  }, [organizationArn, openTelemetry]);

  useEffect(() => {
    setGuestTokenError(undefined);
  }, [resourceGroup, organizationArn]);

  useEffect(() => {
    const supersetContainer =
      selectedDashboardUuid && document.getElementById(selectedDashboardUuid.uuid);
    if (supersetContainer && organizationArn) {
      const payload: GuestTokenPayload = {
        organizationArn: organizationArn,
        dashboardId: selectedDashboardUuid.uuid,
        selectedResourceGroup: resourceGroup || ''
      };

      const fetchGuestTokenCallback = async () =>
        fetchGuestToken(payload, openTelemetry).then(resp => {
          if (resp.error) setGuestTokenError(resp.error);
          return resp.guestToken;
        });

      embedDashboard({
        id: selectedDashboardUuid.uuid,
        supersetDomain: selectedDashboardUuid.domain,
        mountPoint: supersetContainer,
        fetchGuestToken: fetchGuestTokenCallback,
        dashboardUiConfig: {
          urlParams: {lang: language}
        }
      });
      const dashboard = supersetContainer.children[0];
      if (dashboard) {
        dashboard.setAttribute('width', '100%');
        dashboard.setAttribute('frameBorder', '0');
        dashboard.setAttribute(
          'data-testid',
          selectedDashboardUuid?.name.replace(/\s/g, '-').toLowerCase() + '-superset-iframe'
        );
      }
    }
  }, [
    selectedDashboardUuid,
    organizationArn,
    resourceGroup,
    guestTokenError,
    language,
    openTelemetry
  ]);

  return (
    <div className={styles.wrapper} data-testid="superset-page">
      <div className={styles.topBar}>
        <h1 className={styles.header}>Data Insights Dashboard</h1>
        <Dialog>
          <DialogTrigger disableButtonEnhancement>
            <Button icon={<HelpAreaIcon />} appearance="subtle" />
          </DialogTrigger>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Help</DialogTitle>
              <DialogContent>
                <p>Note that:</p>
                <ul>
                  <li>Charts are automatically refreshed every 15 minutes</li>
                  <li>Each chart may refreshed at a different time.</li>
                  <li>The underlying data is refreshed every 15 minutes.</li>
                  <li>It may take up to 30 minutes for an event to affect dashboard.</li>
                </ul>
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="primary">Close</Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </div>
      <TabList
        selectedValue={selectedDashboardUuid?.uuid || ''}
        onTabSelect={(_, data) =>
          setSelectedDashboardUuid(
            availableDashboards.find(dashboard => dashboard.uuid === data.value)
          )
        }
      >
        {availableDashboards.map(dashboard => (
          <Tab key={'tab' + dashboard.name} value={dashboard.uuid}>
            {dashboard.name}
          </Tab>
        ))}
      </TabList>
      {!guestTokenError && (
        <div
          className={styles.supersetcontainer}
          id={selectedDashboardUuid?.uuid}
          data-testid={
            selectedDashboardUuid?.name.replace(/\s/g, '-').toLowerCase() + '-superset-container'
          }
        />
      )}
      {guestTokenError === GuestTokenErrors.NO_DEVICES && (
        <div className={styles.noDevicesMessage}>
          No devices are onboarded in this organization/folder
        </div>
      )}
    </div>
  );
};

export default Superset;
