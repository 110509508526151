type AppConfig = {
  env: 'stage' | 'prod' | 'dev';
  apiURL: string;
  dashboardApiURL: string;
  acxURL: string;
  acxApiKey: string;
  edgeLinkURL: string;
};

const getConfig: () => AppConfig = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'prod':
      return {
        env: 'prod',
        apiURL: 'https://api.did.mysystems.axis.com',
        dashboardApiURL: 'https://dashboard.did.mysystems.axis.com',
        acxURL: 'https://eu.cs.connect.axis.com/internal/graphql',
        acxApiKey: 'a4016192637ae1256c8ed1e0c1903945',
        edgeLinkURL: 'https://api.edgelink.connect.axis.com'
      };
    case 'stage':
      return {
        env: 'stage',
        apiURL: 'https://api.stage.did.mysystems.axis.com',
        dashboardApiURL: 'https://dashboard.stage.did.mysystems.axis.com',
        acxURL: 'https://eu.stage.cs.connect.axis.com/internal/graphql',
        acxApiKey: '30dabe497b14af8df224f50740ec2113',
        edgeLinkURL: 'https://api.stage.edgelink.connect.axis.com'
      };
    case 'dev':
      return {
        env: 'dev',
        apiURL: 'https://api.stage.did.mysystems.axis.com',
        dashboardApiURL: `https://dashboard.${process.env.REACT_APP_USER}.did.mysystems.axis.com`,
        acxURL: 'https://eu.stage.cs.connect.axis.com/internal/graphql',
        acxApiKey: '30dabe497b14af8df224f50740ec2113',
        edgeLinkURL: 'https://api.stage.edgelink.connect.axis.com'
      };
    default:
      throw new Error(`Invalid REACT_APP_ENV "${process.env.REACT_APP_ENV}"`);
  }
};

export const appConfig = getConfig();
