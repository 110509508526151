import {useEffect, useState} from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Image,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
  makeStyles
} from '@fluentui/react-components';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useTranslation} from 'react-i18next';

import {AoAConfiguration, Scenario} from '../../../types';
import {useToken} from '../../../hooks/useToken';
import {fetchAoaConfig} from '../../../fetcher';
import {AxisDevice} from '../../../api/__generated__';
import Panel from '../../../components/Panel';
import {useAppSelector} from '../../../store/hooks';

interface DevicePanelProps {
  readonly device?: Partial<AxisDevice>;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

const useStyles = makeStyles({
  image: {
    width: '100%'
  }
});

const DevicePanel = ({device, isOpen, onClose}: DevicePanelProps) => {
  const styles = useStyles();
  const token = useToken();
  const [img, setImg] = useState<string>();
  const [aoaConfig, setAoaConfig] = useState<AoAConfiguration>();
  const {organization} = usePopulatedTopbarValues();
  const {devicesGeneralDetails} = useAppSelector(state => state.deviceDetailsSlice);
  const {t} = useTranslation();

  useEffect(() => {
    if (device?.serial) {
      const currentDevice = devicesGeneralDetails.find(dev => dev.serial === device.serial);

      if (currentDevice) {
        setImg(currentDevice.img);
      }
    }
  }, [devicesGeneralDetails, device]);

  useEffect(() => {
    if (token && organization?.arn) {
      if (device?.serial) {
        fetchAoaConfig(token, organization.arn, device.serial).then(resp => {
          if (resp) setAoaConfig(resp.data);
        });
      }
    }
  }, [device, organization, token]);
  return (
    <Panel
      title={device && `${device?.model?.name} | ${device?.serial}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Image className={styles.image} src={img} />
      <Accordion collapsible>
        <AccordionItem value="1">
          <AccordionHeader size="large">{t('deviceInfoPanel.existingScenarios')}</AccordionHeader>
          <AccordionPanel>
            <Table size="small">
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Type</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {aoaConfig?.scenarios.map((item: Scenario, index: number) => (
                  /** @todo: list all scenarios (FG, VMD etc) and not just AOA */
                  <TableRow key={index}>
                    <TableCell>
                      <TableCellLayout>{item.name}</TableCellLayout>
                    </TableCell>
                    <TableCell>
                      <TableCellLayout>{item.type}</TableCellLayout>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Panel>
  );
};

export default DevicePanel;
